import Trans from 'next-translate/Trans';
import React, {useEffect, useState} from 'react';
import {styled, withTheme} from 'styled-components';
import Cookies from 'universal-cookie';
import {ClockPlaceholder} from '../../components/animation-placeholders/clock-animation-placeholder';
import {DominoPlaceholder} from '../../components/animation-placeholders/domino-animation-placeholder';
import {PiggyPlaceholder} from '../../components/animation-placeholders/piggy-placeholder';
import {HomeBanner} from '../../components/home-banner';
import {HomeFooter} from '../../components/home-footer';
import {HomeHeader} from '../../components/home-header';
import {LoadingIndicator} from '../../components/loading-indicator';
import {NavWrapper} from '../../components/nav/nav-wrapper';
import {CanNotRenderSeo} from '../../components/seo/fonoma-seo';
import {HomeLandingSeo} from '../../components/seo/home-landing-seo';
import {ZigZag} from '../../components/zig-zag/zig-zag';
import {ZigZagItemClass} from '../../components/zig-zag/zig-zag-item';
import {PAGE_EVENTS, SERVICES} from '../util/constants';
import {
    BaseServerExtraData,
    ExtendedLandingPageProps,
} from '../util/landing-props';
import {getLoginUrl} from '../util/utilities/get-login-url';
import {HomeServerData} from '../util/utilities/get-static-home-props';
import {PersonData, getUserData} from '../util/utilities/get-user-data';
import {AcquisitionKeys} from './acquisition-template';
import {GradientType} from '../../components/header-noise';
import ArrowRight from '../../public/static/landings/miscellanea/arrow-right.svg';
import {getHomeLandingIdentifyData} from '../analytics/get-home-landing-identify-data';
import {getLandingUrl} from '../util/utilities/get-landing-url';
import dynamic from 'next/dynamic';
import {mediaQueries} from '../common';
import {getPromoUrl} from '../util/get-promo-url';

/*
 * We need to disable server-side rendering (SSR) for components that depend
 * on an external script waiting to load in the browser.
 * https://nextjs.org/docs/messages/react-hydration-error
 */
const LandingsReviewsIoCarouselWidget = dynamic(
    () =>
        import('../../components/reviews-io/reviews-io-carousel-widget').then(
            (mod) => mod.LandingsReviewsIoCarouselWidget
        ),
    {
        ssr: false,
    }
);

export const BlackLink = styled.a`
    color: ${({theme}) => theme.colors.ballena};
`;

const ArrowImg = styled(ArrowRight)`
    padding-left: 8px;
`;

const StyledZigZag = styled(ZigZag)`
    padding: 48px 240px;

    ${mediaQueries.lessThan('small')`         
        padding: 8px 16px 24px;
    `}
`;

const Link = styled.a`
    line-height: 1.5;
    letter-spacing: normal;
    color: ${({theme}) => theme.colors.ballena};
    margin-top: ${({theme}): string => theme.spacing.m};
    text-decoration: none;

    &:first-child {
        margin-top: 0;
    }
`;

const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;

const _HomeLandingPageTemplate = <
    LandingServerExtraData extends BaseServerExtraData = BaseServerExtraData,
    LandingNewKeys extends AcquisitionKeys = AcquisitionKeys,
>(
    props: ExtendedLandingPageProps<
        HomeServerData<LandingServerExtraData, LandingNewKeys>
    >
) => {
    const {theme, serverData, shouldRender, isAnnounced, promo, operator} =
        props;
    const {t, lang} = props.i18n;
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;

    const {
        discount,
        serviceName,
        serviceProviderName,
        operatorId,
        countryPrefix,
        mobilePrefixes,
        cellphoneNumberLength,
    } = serverData;

    const canonicalUrl =
        `${SERVER_BASE_URL}/` +
        (props.serverData.serviceName === SERVICES.INTERNET
            ? SERVICES.INTERNET
            : SERVICES.PHONE);

    const [loading, setLoading] = useState(false);
    const [personData, setPersonData] = useState({} as PersonData);
    const [loginUrl, setLoginUrl] = useState('');
    const [toServiceUrl, setToServiceUrl] = useState('');

    const HELP_URL = `${process.env.NEXT_PUBLIC_HELP_URL}/${lang}/`;
    const PROMO_URL = getPromoUrl(lang);

    const footerRightLinks = [];
    const footerLeftLinks = [];
    footerLeftLinks.push(
        <Link href={`${SERVER_BASE_URL}/about`}>{t('footer:who-we-are')}</Link>
    );
    if (process.env.NEXT_PUBLIC_BLOG_URL) {
        footerLeftLinks.push(
            <Link href={process.env.NEXT_PUBLIC_BLOG_URL}>
                {t('footer:blog')}
            </Link>
        );
    } else {
        footerLeftLinks.push(<a>{t('footer:blog')}</a>);
    }
    footerLeftLinks.push(<Link href={HELP_URL}>{t('footer:help')}</Link>);

    if (PROMO_URL) {
        footerRightLinks.push(
            <Link href={PROMO_URL}>{t('footer:promotions')}</Link>
        );
    } else {
        footerRightLinks.push(<a target="_self">{t('footer:promotions')}</a>);
    }

    footerRightLinks.push(
        <Link href={`${SERVER_BASE_URL}/phone`}>
            {t('footer:cellphone-recharge')}
        </Link>
    );
    footerRightLinks.push(
        <Link href={`${SERVER_BASE_URL}/invitation`}>
            {t('footer:invite-your-friends')}
        </Link>
    );

    useEffect(() => {
        const loginUrl = getLoginUrl(document.location.href);
        let toServiceUrl = `${SERVER_BASE_URL}/${SERVICES.INTERNET}${document.location.search}`;

        if (serviceName === SERVICES.INTERNET) {
            toServiceUrl = `${SERVER_BASE_URL}/${SERVICES.PHONE}${document.location.search}`;
        }

        setLoginUrl(loginUrl);
        setToServiceUrl(toServiceUrl);

        const {analytics} = props;
        void analytics.page(undefined, PAGE_EVENTS.LANDING_HOME_PAGE, {
            operator,
            isAnnounced,
            promo,
        });

        const cookies = new Cookies();
        const jwt = cookies.get<string>('jwt');
        if (jwt) {
            setLoading(true);
            void getUserData().then((response) => {
                setPersonData(response);
                setLoading(false);
                if (response.isLoggedIn) {
                    const person = response.personDetails;
                    const [props, opts] = getHomeLandingIdentifyData(person);
                    void analytics.identify(person.id, props, opts);
                }
            });
        }
    }, [isAnnounced, operator, promo, props, serviceName, discount, t]);

    //SetHeader title & subtitle
    let headerTitle = <Trans i18nKey="home_landing:title-promo" />;
    let headerSubtitle = <Trans i18nKey="home_landing:subtitle-phone" />;

    if (serviceName === SERVICES.INTERNET)
        headerSubtitle = <Trans i18nKey="home_landing:subtitle-nauta" />;

    if (!promo) {
        headerTitle = <Trans i18nKey="home_landing:title" />;
    } else if (isAnnounced) {
        headerSubtitle = (
            <>
                <Trans
                    i18nKey="home_landing:cellphone-pre-promo-subtitle"
                    components={[<b key={0} />]}
                    values={{
                        title: discount.promoTitle,
                        date: discount.promoStartDateDisplay,
                    }}
                />
                <br />
                <span>{t('home_landing:schedule-now')}</span>
            </>
        );
    } else {
        headerSubtitle = (
            <Trans
                i18nKey="home_landing:cellphone-promo-subtitle"
                components={[<b key={0} />]}
                values={{
                    title: discount.promoTitle,
                    date: discount.promoEndDateDisplay,
                }}
            />
        );
    }

    if (!shouldRender || (promo && !discount)) {
        return (
            <>
                <CanNotRenderSeo title={t('landings:impossible-to-render')} />
                <h1>{t('landings:page-blank')}</h1>
            </>
        );
    }

    const {title, subtitle} = props.serverData.acquisitionLandingData
        .dynamicTranslations[lang] as LandingNewKeys;

    let isLoggedIn = false;
    const notLoggedInProps = {
        loginUrl: loginUrl,
        i18n: props.i18n,
    };

    let nav = <NavWrapper isLoggedIn={false} navBarProps={notLoggedInProps} />;

    if (personData?.isLoggedIn) {
        isLoggedIn = true;
        const personDetails = personData.personDetails;

        const loggedInProps = {
            personDetails: personDetails,
            longShadow: false,
            i18n: props.i18n,
            analytics: props.analytics,
        };

        nav = <NavWrapper isLoggedIn={true} navBarProps={loggedInProps} />;
    }

    //Texts for phone service type
    let buttonText = t('home_landing:send-recharge');
    let labelText = t('home_landing:send-recharge-label');
    let descriptionKey = 'description-phone';
    let serviceComponents = [
        <b key={0} />,
        <b key={1} />,
        <BlackLink href={toServiceUrl} key={2} />,
    ];

    if (serviceName === SERVICES.INTERNET) {
        buttonText = t('home_landing:send-nauta-recharge');
        labelText = t('home_landing:send-nauta-recharge-label');
        descriptionKey = 'description-nauta';
        serviceComponents = [
            <b key={0} />,
            <BlackLink href={toServiceUrl} key={1} />,
            <b key={2} />,
        ];
    }

    const placeholders = [
        <ClockPlaceholder key={0} />,
        <PiggyPlaceholder key={1} />,
        <DominoPlaceholder key={2} />,
    ];

    const zigzagItems = [];
    zigzagItems.push(
        new ZigZagItemClass(
            null,
            placeholders[0],
            (
                <Trans
                    i18nKey={`home_landing:zigzag_item_${0}.${descriptionKey}`}
                    components={serviceComponents}
                />
            ),
            t(`home_landing:zigzag_item_${0}.title`),
            '',
            ''
        )
    );

    zigzagItems.push(
        new ZigZagItemClass(
            null,
            placeholders[1],
            (
                <Trans
                    i18nKey={`home_landing:zigzag_item_${1}.description`}
                    components={[
                        <b key={0} />,
                        <b key={1} />,
                        <BlackLink href="/" key={2} />,
                    ]}
                />
            ),
            t(`home_landing:zigzag_item_${1}.title`),
            '/invitation',
            (
                <div>
                    {t(`home_landing:zigzag_item_${1}.linkText`)}
                    <ArrowImg />
                </div>
            )
        )
    );

    return (
        <>
            {loading && (
                <LoadingIndicator opacity={1} color={theme.colors.arroz} />
            )}

            <HomeLandingSeo
                title={t('home_landing:htmlTitle')}
                canonicalUrl={canonicalUrl}
                description={t('metas:description-key')}
                ogTitle={t('metas:og-title')}
                ogDescription={t('metas:og-description')}
            />

            {nav}
            <HomeHeader
                title={headerTitle}
                subtitle={headerSubtitle}
                labelText={labelText}
                buttonText={buttonText}
                serviceName={serviceName}
                serviceProviderName={serviceProviderName}
                operatorId={operatorId}
                cellphoneNumberLength={cellphoneNumberLength}
                mobilePrefixes={mobilePrefixes}
                countryPrefix={countryPrefix}
                isLoggedIn={isLoggedIn}
            />

            <StyledZigZag items={zigzagItems} zigzag={true} />

            <HomeBanner
                heading={t('home_landing:banner-info') + PROJECT_NAME}
                title={title}
                subtitle={subtitle}
                actionText={t('home_landing:banner-action')}
                actionLink={getLandingUrl(operator, true)}
                gradient={GradientType.HomeCenter}
            />

            <LandingsReviewsIoCarouselWidget
                lang={lang}
                dataStore="www.fonoma.com"
            />

            <HomeFooter
                rightLinks={footerRightLinks}
                leftLinks={footerLeftLinks}
            />
        </>
    );
};

export const HomeLandingPageTemplate = withTheme(_HomeLandingPageTemplate);
